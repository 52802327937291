import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BigShortService {
  constructor(public http: HttpClient) {}
  baseUrl: string = environment.apiUrl;

  isBigShortChallengeActive = signal(false);

  bigShortAndStaticBanner(): Observable<any> {
    return this.http.get(`${this.baseUrl}/viewer/film/bigShortStaticBanner`);
  }
}
