import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { QRCodePayload } from '@app/pages/shared/interfaces/qr-code.interface';
import { QRCodeService } from '@app/pages/shared/services/qr-code.service';
import { ToasterService } from '@app/core/services/toaster.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@app/core/services/modal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, OnDestroy {
  phoneNumberFormat = PhoneNumberFormat;
  currentUserCountryCode: any = CountryISO.India.toUpperCase();
  unSubscribeAll: Subject<any> = new Subject<void>();

  @ViewChild('qrError', { static: false })
  qrError!: TemplateRef<any>;
  errorModal: BsModalRef | null = null;
  inputMobileNumber: any;
  data: QRCodePayload = {
    phoneNumber: null,
    qrCodeId: null,
    countryCodeNumeric: '+91',
    countryCode: 'IN',
  };
  qrErrorMessage: string | null = 'Something Went Wrong!';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly qrCodeService: QRCodeService,
    private readonly toaster: ToasterService,
    private readonly modalService: ModalService,
    private readonly router: Router,
    private readonly deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    // fetch the `qr-code-id` parameter from the route
    this.data.qrCodeId = this.route.snapshot.paramMap.get('qrCodeId');

    if (!this.data.qrCodeId) {
      this.toaster.error('QR code not found!');
    }
  }

  onSubmit() {
    if (!this.inputMobileNumber?.number) {
      this.toaster.error('Please enter valid mobile number');
      return;
    }
    if (!this.data.qrCodeId) {
      this.toaster.error('Invalid QR code');
    }

    this.data.phoneNumber = this.inputMobileNumber?.number;
    this.data.countryCodeNumeric = this.inputMobileNumber?.dialCode;
    this.data.countryCode = this.inputMobileNumber?.countryCode;
    this.qrCodeService
      .submitQrCodeDetails(this.data)
      .pipe(takeUntil(this.unSubscribeAll))
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.toaster.success(res.message);
            if (this.deviceService.isMobile()) {
              if (this.deviceService.os.toLowerCase() == 'ios') {
                this.router.navigate(['static/device/ios']);
              } else if (this.deviceService.os.toLowerCase() == 'android') {
                this.router.navigate(['static/device/android']);
              } else {
                this.router.navigate(['/']);
              }
            }
            else {
              this.router.navigate(['/']);
            }
          }
          else {
            this.qrErrorMessage = res.message;
            this.errorModal = this.modalService.show(this.qrError, {
              class: 'modal-dialog-centered',
              backdrop: 'static',
            });
          }
        },
        error: (error: any) => {
          this.qrErrorMessage = error.error.message;
          this.errorModal = this.modalService.show(this.qrError, {
            class: 'modal-dialog-centered',
            backdrop: 'static',
          });
        },
      });
  }

  onclose() {
    this.errorModal?.hide();
  }
  ngOnDestroy(): void {
    if (this.unSubscribeAll) {
      this.unSubscribeAll.next(null!);
      this.unSubscribeAll.complete();
    }
  }
}
