import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { QRCodePayload } from '../interfaces/qr-code.interface';

@Injectable({
  providedIn: 'root',
})
export class QRCodeService {
  baseUrl: string = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) {}

  submitQrCodeDetails(payload: QRCodePayload) {
    return this.httpClient.post(`${this.baseUrl}/qrCode/scan`, payload, {
      headers: {
        Authorization: 'Basic SmF6ek11c2ljQDEyMw==',
      },
    });
  }
}
