import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommonResponse,
  OtpData,
} from '@app/auth/shared/interfaces/sign-in-result.interface';
import {
  ApiResponse,
  ApiResponseModel,
} from '@app/core/models/api-response.model';
import { ViewerProfileModel } from '@app/core/models/user.model';
import { PasswordModel } from '@app/core/shared/role/role.model';
import { environment } from '@environments/environment';
import { Observable, of, tap } from 'rxjs';
import {
  ChangePassinput,
  UpdateInput,
  UserProfile,
} from '../interfaces/viewer-profile.interface';
import { UserCountryService } from '@app/public/shared/services/user-country.service';
import {
  SavedPreference,
  Preferences,
  PreferencePayload,
} from '@app/core/shared/user';

@Injectable({
  providedIn: 'root',
})
export class ViewerProfileService {
  baseUrl: string = environment.apiUrl;
  constructor(
    public http: HttpClient,
    private readonly userCountryService: UserCountryService
  ) {}

  getProfile(): Observable<ApiResponseModel<ViewerProfileModel>> {
    return this.http.get<any>(`${this.baseUrl}/user/profile`).pipe(
      tap((response: any) => {
        if (
          response.data &&
          response.data.countryCode &&
          response.data.countryCode.trim() !== ''
        ) {
          this.userCountryService.setCountryCode(response.data.countryCode);
        }
      })
    );
  }

  sendNotificationInfo(isNotificationSend: boolean): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/user/profile?isNotificationSend=${isNotificationSend}`
    );
  }

  changePassword(chngData: ChangePassinput): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      `${this.baseUrl}/user/change-password`,
      chngData
    );
  }

  setPassword(param: PasswordModel): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${this.baseUrl}/user/reset-password`,
      param
    );
  }

  updateViewerProfile(
    updateInput: UpdateInput
  ): Observable<ApiResponseModel<UserProfile>> {
    return this.http.put<ApiResponseModel<UserProfile>>(
      `${this.baseUrl}/user/profile`,
      updateInput
    );
  }

  sendProfileOtp(type: string, otpData: OtpData): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/user/get-otp?type=${type}`,
      otpData
    );
  }

  resendEmail(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/user/send-verify-email`);
  }

  sendVerifyEmail(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/user/send-verify-email`,
      payload
    );
  }

  uploadProfilePicture(profilePic: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/user/profilePic`, profilePic);
  }

  updateFinancialDetails(financeData: FormData): Observable<any> {
    return this.http.put<any>(
      `${this.baseUrl}/user/profile?type=FINANCE`,
      financeData
    );
  }

  sendFCMToken(param: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/user/add/fcmToken`, param);
  }

  getPreferencesList(): Observable<ApiResponse<Preferences>> {
    return this.http.get<ApiResponse<Preferences>>(
      `${this.baseUrl}/user/preferencesLookup`
    );
  }

  getSavedPreferences(): Observable<ApiResponse<SavedPreference>> {
    return this.http.get<ApiResponse<SavedPreference>>(
      `${this.baseUrl}/viewer/userPreference`
    );
  }

  // No need for data hence given type any
  saveUserPreferences(
    payload: PreferencePayload
  ): Observable<ApiResponse<any>> {
    return this.http.put<ApiResponse<any>>(
      `${this.baseUrl}/viewer/preferences`,
      payload
    );
  }
}
