import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from '@app/core/services/cookie.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  returnUrl: string | null;
  constructor(private route: ActivatedRoute, private readonly router: Router) {
    this.returnUrl = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void { }

  onclick() {
    let d: Date = new Date();
    d.setTime(d.getTime() + 14 * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    document.cookie = `kGlnOFjvPy=1; ${expires}`;
    this.router.navigate(["/"]);
  }
}
