<div class="qr-code-redemption-page">
  <div class="qr-head">
    <img
      src="../../../assets/images/abc-logo.svg"
      width="127"
      height="50"
      title="ABC Talkies"
      alt="ABC-Talkies"
    />
  </div>
  <div class="qr-body">
    <div class="title">
      <h3>{{ "qr.title" | translate }}</h3>
      <p class="abc-green-font">
        {{ "qr.text" | translate }}
      </p>
    </div>
    <div class="mt-25 form">
      <form #qrCodeForm="ngForm" (submit)="onSubmit()">
        <div class="abc-input-field">
          <label for="mobile-number">Mobile Number</label>
          <ngx-intl-tel-input
            id="mobile-number"
            name="mobile-number"            
            [(ngModel)]="inputMobileNumber"
            [selectFirstCountry]="true"
            [numberFormat]="phoneNumberFormat.National"
            [selectedCountryISO]="currentUserCountryCode"
            [preferredCountries]="['in']"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [selectFirstCountry]="false"
            [searchCountryFlag]="true"
            [maxLength]="10"
            customPlaceholder="Enter Your Mobile Number"
            required
          >
          </ngx-intl-tel-input>
        </div>
        <div class="mt-25">
          <div class="abc-form-button">
            <button
              type="submit"
              class="only-button"
              [disabled]="!qrCodeForm.valid"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #qrError>
  <app-qr-error-dialog
    [message]="qrErrorMessage"
    (onclose)="onclose()"
  ></app-qr-error-dialog>
</ng-template>
